import {createApp} from 'vue';
import HeaderMenu from "./HeaderMenu.vue";
import HeaderSearch from "./HeaderSearch.vue";

createApp({
    components: {
        'header-menu': HeaderMenu,
        'header-search': HeaderSearch
    },
    /**
     * Delimiter for twig template n vue components we still use mustaches `{{ }}`
     */
    delimiters: ['${', '}']
}).mount('#header-app');