<script setup>
import {ref} from 'vue';
import {
  BoltIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  UserPlusIcon,
  XCircleIcon,
  Cog6ToothIcon,
  PhoneIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  BanknotesIcon,
  ShoppingBagIcon,
  CurrencyDollarIcon,
  HeartIcon,
  SparklesIcon
} from '@heroicons/vue/24/outline';

const props = defineProps({
  config: {
    type: String,
    required: true
  }
});

const itemNameToIcon = {
  'advertise': BoltIcon,
  'back-to-admin': XCircleIcon,
  'admin': Cog6ToothIcon,
  'provider-phone': PhoneIcon,
  'provider-dashboard': SparklesIcon,
  'visitor-dashboard': SparklesIcon,
  'role-select': UserGroupIcon,
  'contact-us': QuestionMarkCircleIcon,
  'support': ChatBubbleLeftRightIcon,
  'payment-recharge': BanknotesIcon,
  'logout': LockClosedIcon,
  'login': LockClosedIcon,
  'register': UserPlusIcon,
  'products': ShoppingBagIcon,
  'transactions': CurrencyDollarIcon,
  'favorites': HeartIcon,
}

let config;

try {
  config = JSON.parse(props.config);
} catch {
  config = [];
}

const isOpen = ref(false);
const logoutFormRef = ref(null);

function toggle() {
  isOpen.value = !isOpen.value;
}

function onLinkClick(linkItem, event) {
  if (linkItem.jsLink) {
    window.location = linkItem.href;
    return;
  }
  if (linkItem.name === 'logout') {
    event.preventDefault();
    logoutFormRef.value?.[0]?.submit();
    return;
  }

}

</script>

<template>
  <slot :toggle="toggle"></slot>
  <div
      :class="{hidden: !isOpen}"
      class="absolute right-0 top-9 z-20 mt-2 w-48 origin-top-right rounded-md bg-white border border-gray-200 divide-y divide-gray-100 shadow-lg"
      id="user-menu" role="menu" aria-orientation="vertical"
      aria-labelledby="user-menu-button" tabindex="-1">
    <div v-for="group in config" class="py-1" role="none">
      <template v-for="link in group">
        <a v-if="!link.jsLink"
           :class="[link.textClass || 'text-gray-700 alternative']"
           :href="link.href"
           class="group flex items-center px-4 py-2 text-sm"
           @click="onLinkClick(link, $event)"
           role="menuitem"
           tabindex="-1"
        >
          <component :is="itemNameToIcon[link.name] || 'template'"
                     class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
          {{ link.label }}
        </a>
        <button v-if="link.jsLink"
                :class="[link.textClass || 'text-gray-700 alternative']"
                href="#"
                class="group font-bold flex items-center px-4 py-2 text-sm text-gray-700 hover:text-main-500"
                @click="onLinkClick(link, $event)"
                role="menuitem"
                tabindex="-1"
        >
          <component :is="itemNameToIcon[link.name] || 'template'"
                     class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
          {{ link.label }}
        </button>
        <template v-if="link.name === 'logout'">
          <form :action="link.href" method="POST" class="hidden" ref="logoutFormRef">
            <input type="hidden" name="_token" :value="link.csrfToken"/>
          </form>
        </template>
      </template>
    </div>
  </div>
</template>
