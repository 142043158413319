<script setup>
import {onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import debounce from 'lodash.debounce';

const cache = {};

const {genderLinksJson, defaultSearchValue, userGender} = defineProps({
  genderLinksJson: {
    type: String,
    required: true,
  },
  userGender: {
    type: String,
  },
  defaultSearchValue: {
    type: String,
    default: ''
  }
});


const isGenderListOpen = ref(false);
const searchQuery = ref(defaultSearchValue);
const searchResults = ref(null);
let genderLinks = [];

try {
  genderLinks = JSON.parse(genderLinksJson);
} catch {
}

const doSearchRequest = debounce(async function () {
  const searchQueryValue = searchQuery.value;

  if (cache[searchQueryValue]) {
    searchResults.value = cache[searchQueryValue];
    return;
  }

  try {
    const requestUrl = new URL(window.location.href);
    let originalUrl = requestUrl.pathname
    requestUrl.pathname = '/api/v1/search';
    requestUrl.searchParams.append('g', userGender);
    requestUrl.searchParams.append('q', encodeURIComponent(searchQueryValue));
    //TODO: TEMP. Add ability to handle other languages
    if (originalUrl.includes('/es/')) {
      let locale = '';
      locale = 'es';
      requestUrl.searchParams.append('l', locale);
    }
    if (originalUrl.includes('/pt/')) {
      let locale = '';
      locale = 'pt';
      requestUrl.searchParams.append('l', locale);
    }
    if (originalUrl.includes('/fr/')) {
      let locale = '';
      locale = 'fr';
      requestUrl.searchParams.append('l', locale);
    }

    const response = await (await fetch(requestUrl.toString())).json();

    cache[searchQueryValue] = response;
    searchResults.value = response;
  } catch {
  }
}, 500);

function onSearchChange(event) {
  searchQuery.value = event.target.value;
}

function onSearchFocus() {
  doSearchRequest(userGender, searchQuery.value);
}

function toggleGenderList() {
  isGenderListOpen.value = !isGenderListOpen.value;
}

function cleanUpResults() {
  searchResults.value = null
}

watch(searchQuery, async () => {
  if (searchQuery.value.length < 2) {
    cleanUpResults();
    return;
  }

  doSearchRequest();
})

onMounted(() => {
  document.addEventListener('click', cleanUpResults);
  document.addEventListener('tap', cleanUpResults);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', cleanUpResults);
  document.removeEventListener('tap', cleanUpResults);
});

</script>

<template>
  <div class="flex md:relative">
    <div class="flex items-center text-sm relative">
      <slot :toggle-gender-list="toggleGenderList"
            :on-search-focus="onSearchFocus"
            :on-search-change="onSearchChange"
            :search-query="searchQuery"
      />

      <div
          :class="{hidden: !isGenderListOpen}"
          class=" absolute left-0 z-10 mt-2 w-56 top-full origin-top-right border border-gray-100 divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
          tabindex="-1">
        <div role="none">
          <a v-for="link in genderLinks"
             :href="link.url"
             class="text-gray-700 alternative hover:bg-gray-100 group flex items-center px-4 py-3 text-sm"
             role="menuitem" tabindex="-1">
            <img class="mr-3" :src="link.icon" :alt="link.gender">
            <span class="capitalize">
              {{ link.gender }}
              <template v-if="link.count">
                ({{ link.count }})
              </template>
            </span>
          </a>
        </div>
      </div>
      <div class="absolute top-14 left-0 md:mx-0 md:top-12 w-full z-20" v-if="searchResults">
        <div class="m-auto md:w-full bg-white shadow-xl rounded-md overflow-y-scroll max-h-96 max-w-md">
          <div class="js-search-results">
            <div v-for="location in [...(searchResults?.locations || []), ...(searchResults?.profileLocations || [])]"
                 class="hover:bg-gray-300/30 hover:cursor-pointer">
              <a :href="location.link" class="flex p-2 justify-content-start items-center alternative">
                <img class="ml-5 mr-8 w-4 h-4 " src="/icons/marker.svg" alt="Marker"/>
                <div class="flex flex-wrap">
                  <div class="w-full">
                    <span class="name" v-html="location.name"/>
                    <span class="code text-gray-500">{{ location.code }}</span>
                  </div>
                  <div class="text text-gray-400 capitalize">{{ location.text }} ({{ location.count }})</div>
                </div>
              </a>
            </div>
            <div v-for="profile in (searchResults?.profiles || [])"
                 class="p-1 hover:bg-gray-300/30 hover:cursor-pointer">
              <a :href="profile.link" class="flex justify-content-start items-center text-black-900">
                <img :src="profile.image" class="mr-2 w-16 h-16 object-cover rounded-md"/>
                <div>
                  <div>
                    <span class="name capitalize" v-html="profile.name"/>
                    <span class="age text-gray-500">{{ profile.age }}</span>
                  </div>
                  <div>
                    <span class="location text-gray-300">{{ profile.location }}</span>
                    <span class="rate text-gray-300">{{ profile.rate }}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
